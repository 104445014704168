// ** Auth Endpoints
const url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : process.env.REACT_APP_BASE_URL_DEV

export default {
  loginEndpoint: `${url}/auth/login`,
  registerEndpoint: "/jwt/register",
  refreshEndpoint: `${url}/auth/refresh-tokens`,
  logoutEndpoint: "/jwt/logout",
  getRoles: `${url}/roles`,
  getAllRoles: `${url}/roles/all`,
  register: `${url}/auth/register`,
  login: `${url}/auth/login`,
  logout: `${url}/auth/logout`,
  forgotPassword: `${url}/auth/forgot-password`,
  otpVerified: `${url}/auth/verify-otp`,
  otpResend: `${url}/auth/resend-otp`,
  resetPassword: `${url}/auth/reset-password`,
  userProfile: `${url}/users`,
  employee: `${url}/employee/getids`,
  createUsers: `${url}/users`,
  roleUserList: `${url}/users`,
  updatePassword: `${url}/users/update-password`,
  updateBusinessEmail: `${url}/users/update-email`,
  addCompany: `${url}/company`,
  addProfileImage: `${url}/users/update-profile-img`,
  addDepartment: `${url}/departments`,
  depertmentList: `${url}/company/department`,
  employeetList: `${url}/company`,
  employeeReview: `${url}/employeeReview`,
  templateRating: `${url}/rating/templates`,
  addTemplateRating: `${url}/rating/add-template`,
  deleteTemplateRating: `${url}/rating/template`,
  employeeReviewedRatings: `${url}/rating/template`,
  managerReviewAllReviews: `${url}/managerReview/all-reviews`,
  managerReviewMyReviews: `${url}/managerReview/my-reviews`,
  managerReviewAsMangerReviews: `${url}/managerReview/reviews-as-manager`,
  managerReviewAssignedEmployees: `${url}/managerReview/assigned-employees`,
  asManagerEndpoint: `${url}/employeeReview/AsManager`,
  employeeReviewOpenedEndPoint: `${url}/employeeReview/opened`,
  employeeReviewCompletedEndPoint: `${url}/employeeReview/completed`,
  usersArchivementsEndPoint: `${url}/users/archivements`,

  activityUsers: `${url}/users/get-user-activities`,
  uploadUsers: `${url}/users/upload`,
  launchpad: `${url}/launchpad`,
  approvalgroup: `${url}/approvalgroup`,
  getUserLaunchPads: `${url}/launchpad/get-user-all-launchpads`,
  getApprovalCompanygroup: `${url}/approvalgroup/company`,
  getCompanyEmployees: `${url}/company`,
  getRatingApprovalGroups: `${url}/rating/approval-groups`,
  ratingAdd: `${url}/rating/add`,
  notification: `${url}/notifications`,
  notificationUnRead: `${url}/notifications/unread`,
  notificationRead: `${url}/notifications/read`,
  allEmployeesList: `${url}/employee/all/employees`,
  duplicateTemplate: `${url}/rating/duplicate-template`,
  employeeRoleTypes: `${url}/employee/role-types`,
  employeeReviewedList: `${url}/employee/reviewed`,
  addCommentOnRating: `${url}/rating/update-by-manager`, 
  genrateTemplateReviews: `${url}/rating/genrate-template-reviews`,
  managerDashboardReviews: `${url}/managerReview/dashboard`,
  employeeReviewUpdateReviewStatus: `${url}/employeeReview/update-review-status`,
  managerReviewSubmitedReviews: `${url}/managerReview/submited-reviews`,
  managerReviewOpenReviews: `${url}/managerReview/open-reviews`,
  managerReviewSendReminder: `${url}/managerReview/send-reminder`,
  employeeUpcomingMeetings: `${url}/employee/upcoming-meetings`,
  approvalgroupTemplateEndPoint: `${url}/approvalgroup/template`,
  employeeHistory : `${url}/employeeReview/employee-history`,
  ownHistory : `${url}/employeeReview/own-history`,
  reviewDetails : `${url}/employeeReview/view-form`,

  addReview : `${url}/rating/add`,
  updateReview : `${url}/rating/update`,

  // employeeReviewedRatings: `${url}/employeeReview/employee`,
  // 
  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: "Bearer",

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: "accessToken",
  storageRefreshTokenKeyName: "refreshToken",


  // leaning center
  addTopic :  `${url}/topics/add`,
  topics : `${url}/topics`,
  deleteTopics : `${url}/topics/delete`,
  deleteSession : `${url}/sessions/delete`,
  updateTopics : `${url}/topics/update`,
  sessionsAll :  `${url}/sessions/all`, 
  sessions :  `${url}/sessions`, 
  addSession : `${url}/sessions/add`,
  updateSession : `${url}/sessions`,
  submitQuiz : `${url}/sessions/fill`,
  updateStatus :  `${url}/topics/update-status`,
  totalPoints :  `${url}/sessions/total-points`,
  sessionOfTheWeek : `${url}/sessions/week-session`,
}
